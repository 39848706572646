@import '_variables';

h1 {
  font-family: 'Overpass Mono';
  font-size: 70px;
}

p {
  line-height: 130%;
  word-wrap: break-word;

  &.time {
    color: $secondary-color
  }
}

h3 {
  color: #656565;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

body {
  color: $text-color;
  background-color: $background;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  background-color: $background-light;
  border: none;
  color: $text-color;
  font-family: 'Overpass Mono';
}

.content {
  .flex {
    display: flex;
    justify-content: center;
  }
}