.content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.flex {
  width: 100%;
  max-width: 960px;
  margin: 16px;
}