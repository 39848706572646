$gap: 34px;

.feed-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 742px) {
        flex-direction: row;
    }

    .feed-settings {

        @media (max-width: 742px) {
            display: none;
        }
    }

    .feed-content {
        width: 100%;

        @media (min-width: 742px) {
            margin-left: 38px;
        }
    }


    .align-center {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .extend-container {
        display: flex;
        width: 100%;
        justify-content: center;

        button.more {
            cursor: pointer;
            padding: 0;
            border-radius: 100%;
            background: transparent;

            img {
                height: 45px;
                width: auto;
            }
        }
    }
}