#about-us {
    margin-top: 64px;

    p {
        font-size: 18px;
        font-family: Inter;
        color: #fff;
        margin-bottom: 32px;

        a {
            color: #5193e3;
            text-decoration: underline;
        }
    }
}