.modal-content {
    display: flex;
    flex-direction: column;
    background-color: #101B2D;
    padding: 16px;

    .title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #2A3A4D;
        align-items: center;

        p.share {
            font-family: Inter;
        }
    }

    .buttons {
        display: flex;
        justify-content: space-evenly;
        margin-top: 16px;
        margin-bottom: 16px;

    }

    button {
        cursor: pointer;

        background-color: transparent;

        img {
            width: 80px;
            height: 80px;

        }

        &.copy {
            background-color: #12233F;
            display: flex;
            justify-content: space-between;
            padding: 8px;

            align-items: center;

            img {
                width: 30px;
                height: 37px;

            }
        }

        &.close {
            background-color: transparent;

            img {

                width: 40px;
                height: 40px;
            }
        }
    }
}