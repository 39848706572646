@import '../../variables';

.teaser-root {
    max-width: 600px;
    background-color: $background-light;
    color: $text-color;
    padding: 22px;
    border-radius: 10px;

    margin-bottom: 38px;

    @media (max-width: 742px) {
        margin-top: 38px;
        margin-bottom: 0;
    }

    .teaser-content {

        .img-container {
            max-width: 478px;
            max-height: 175px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }

        h5 {
            font-family: 'Overpass';
            font-weight: 300;
            font-size: 22px;
            margin: 12px 0;
        }

        p.teaser {
            font-family: 'Inter';
            font-size: 16px;
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            margin: 16px 0;
        }

        .grade-bar-root {
            margin: 8px 0;
        }

        .time {
            font-family: Inter;
            font-size: 16px;
        }

        button.details {
            font-family: Inter;
            background: none;
            border: none;
            text-decoration: underline;
            color: $text-color;
            font-size: 16px;
            cursor: pointer;
        }
    }

}