@import '../../_variables';

nav {


    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 87px;

    @media (max-width: 1176px) {
        display: block;
    }

    button.bm-item {
        background: none;
        font-size: 24px;
        margin-bottom: 18px;
    }

    .nav-content {
        width: 100%;
        max-width: 1160px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img.logo {
            padding: 8px;
            padding-left: 0px;
        }

        .nav-desktop {
            display: flex;
            flex-direction: column;
            width: 100%;

            .nav-links {
                width: 100%;
                display: flex;
                justify-content: space-around;
                border-bottom: 1px solid #D4D4D4;

                button {
                    cursor: pointer;
                    background-color: transparent;
                    padding: 14px 10px;
                    margin: 8px 0;

                    &:hover {
                        background-color: $background-light;
                        border-radius: 10px;
                    }
                }
            }

            .nav-header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #d4d4d4;

                @media (max-width: 742px) {
                    display: none;
                }

                button {
                    background-color: $background-light;
                    padding: 14px 10px;
                    height: fit-content;
                    align-self: center;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        background-color: #0d2a58;

                    }

                    img {
                        margin-left: 12px;
                        width: 32px;
                        height: auto;
                    }
                }
            }
        }

        .nav-mobile {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 1000;
            background-color: $background;
            border-bottom: solid #7A828F 2px;

            @media (min-width: 746px) {
                display: none;
            }

            h1 {
                font-weight: bold;
                font-size: 40px;
                font-family: 'Overpass Mono';
                color: $primary-color;
                text-align: center;
                width: 100%;
                margin: 18px 0;
            }
        }

        .nav-menu {
            button {
                border: none;
                background-color: white;
                color: #000;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration: none;
                margin-left: 60px;
                position: relative;
                cursor: pointer;

                &::before {
                    content: '\2022'; // Unicode character for a bullet point
                    color: #000;
                    margin-right: 10px; // Adjust the spacing between the dot and the text
                    position: absolute;
                    left: -35px; // Adjust the position of the dot
                    top: 6px;
                    font-size: 10px;
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                }

                &:last-child {
                    margin-right: 16px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            @media (max-width: 1176px) {
                display: none;
            }
        }
    }
}