@import '../../variables';

.article-root {
    font-family: 'Inter';
    padding: 22px;
    max-width: 600px;
    background-color: $background-light;
    color: $text-color;
    border-radius: 10px;

    margin-bottom: 38px;

    @media (max-width: 742px) {
        margin-top: 38px;
        margin-bottom: 0;
    }

    .source-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        .source {
            display: flex;
            align-items: center;

            img.icon {
                width: 42px;
                height: 42px;
                border-radius: 50%;
            }

            .sourceName {
                margin-left: 12px;
            }
        }

        .timedate {
            position: relative;
            display: flex;
            align-items: center;

            .full-date {
                position: absolute;
                top: 100%;
                left: 0;
                font-size: 14px;
                color: $secondary-color;
                background-color: $background-light;
                padding: 4px 8px;
                border-radius: 4px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .article {
        margin-top: 26px;

        span.highlight {
            background-color: #414F32;
        }


        .comment {
            z-index: 2;
            position: absolute;
            top: 26px;
            left: 50%;
            transform: translateX(-50%);
            width: 250px;
            background-color: $text-color;
            color: $background;
            padding: 12px 16px;
            border-radius: 4px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        }
    }

    .bottom {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        a.redirect {
            color: $text-color;
            text-align: right;
            padding: 4px 8px;
            margin-top: 20px;
        }
    }
}

.methods-control {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid $secondary-color;

    h2 {
        color: $tetriary-color;
    }

    .button-list {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        flex-direction: column;

        button {
            margin-top: 8px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 8px 0;
            border-radius: 4px;
            border: none;
            color: $text-color;
            background-color: $background;
            font-family: 'Overpass', sans-serif;
            font-size: 16px;

            .MuiSvgIcon-root {
                color: $tetriary-color;
            }

            .placeholder {
                width: 16px;
                height: 42px;
            }

            p {
                text-align: left;
            }
        }

    }
}