// components/GradeBar/GradeBar.scss
.grade-bar-root {
    display: flex;

    .grade-bar {
        display: flex;
        align-items: center;
        width: 100%;
        /* Set your desired overall width */
        height: 20px;
        /* Background color of the entire bar */
        position: relative;
    }

    .filled-bar {
        height: 100%;
        background-color: #4caf50;
        border-radius: 4px;
        /* Filled bar color */
        transform: skewX(-20deg);
        /* Adjust the skew angle */
        position: relative;

    }

    .empty-bar {
        flex-grow: 1;
        height: 100%;
        border-radius: 4px;
        background-color: none;
        /* Transparent inside */
        border: 3px solid #fff;
        /* Border color */
        transform: skewX(-20deg) translateX(2px);
        /* Adjust the skew angle */
    }

    .grade-label {
        font-family: Inter;
        text-wrap: nowrap;
        margin-left: 16px;
        font-weight: 600;
        font-size: 16px;
        color: #333;
        /* Grade label color */
    }
}