@import "../../variables.scss";

.analyze-text-root {
    margin-top: 64px;
    font-size: 18px;
    font-family: Inter;
    color: #fff;
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .notification-banner {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        padding: 8px;
        border-radius: 8px;
        width: 100%;
        margin-bottom: 16px;
    }

    .notification {
        margin-top: 16px;
    }

    .analyze-text {
        background-color: #12233f;
        padding: 16px;
        border-radius: 8px;
        width: 100%;
        max-width: 725px;

        h3 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 16px;
            color: #fff;
            text-align: center;
        }

        textarea {
            max-width: 725px;
            width: 100%;
            height: 327px;
            color: #fff;
            border: 1px solid #5193e3;
            background-color: #12233f;
            border-radius: 8px;
            padding: 16px;
            font-size: 16px;
            font-family: Inter;
            margin-bottom: 16px;
        }

        .wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 16px;
            width: 100%;

            button {
                background-color: transparent;
                color: #fff;
                border: 1px solid #fff;
                border-radius: 8px;
                padding: 16px;
                font-size: 16px;
                font-family: Inter;
                cursor: pointer;
                margin-top: 16px;
            }

        }

        .notification {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            border: 1px solid #5193e3;
            color: #e3cd51;
            padding: 8px;
            border-radius: 8px;
            width: 100%;
        }



    }

    .article-root {
        margin-top: 64px;
    }
}