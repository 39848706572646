.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 4px solid #fff;
    border-radius: 50%;
    border-top-color: #75b042;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}