// Colors
$primary-color: #5F85F5;
$text-color: #E8E8E8;
$secondary-color: #B3B3B3;
$tetriary-color: #4DB6AC;
$success-color: #28a745;
$error-color: #dc3545;
$cancel-color: #FF7B83;
$primary-light: #7FD1FF;

// background 
$background: #101B2D;
$background-light: #12233F;


// Fonts
$base-font-family: 'Arial', sans-serif;
$heading-font-family: 'Roboto', sans-serif;

// Font Sizes
$base-font-size: 16px;
$heading-1-font-size: 2rem;
$heading-2-font-size: 1.5rem;
$heading-3-font-size: 1.2rem;
// ... add more as needed