.notification-root {
    width: 100%;
    margin-bottom: 16px;

    div {
        border-radius: 8px;
        padding: 8px 16px;
        font-family: "Overpass";
    }

    .notification-error {
        background-color: #d32f2f85
    }
}