@import '../../variables';

.sort-filter-desktop {
    width: 400px;
    font-family: 'Overpass';

    .header {
        display: flex;
        align-items: center;

        img {
            width: 40px;
            height: 40px;
            margin: 8px 16px;
        }
    }

    .sort,
    .filter {
        background-color: $background-light;
        border-radius: 10px;
        padding: 12px;

        .control {
            display: flex;
            margin-top: 12px;
            padding-bottom: 8px;
            justify-content: space-between;

            button {
                cursor: pointer;
                padding: 14px 10px;
                margin: 8px;
                color: $primary-light;
                border-radius: 10px;
                line-height: 8px;

                &.reset {
                    color: $primary-light;
                    border: 1px solid $primary-light;
                }

                &.accept {
                    color: $background-light;
                    background-color: $primary-light;
                }
            }
        }

        .filter-inputs {
            margin: 0 8px;

            .MuiFormControl-root {
                width: 100%;
                margin: 0;
                margin-top: 16px;

                .MuiOutlinedInput-notchedOutline {
                    border-color: gray;
                }

                .MuiSelect-select {
                    background-color: $background-light;
                    border-radius: 10px;
                    width: 100%;

                }
            }

            label {
                color: $text-color;
            }

            .MuiSvgIcon-root {
                color: $text-color;
            }

            .MuiChip-root {
                background-color: $background;
                color: $text-color;
            }
        }
    }

    .sort {
        margin-bottom: 34px;

        .list {
            display: flex;
            flex-direction: column;

            button {
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                padding: 14px 10px;
                background-color: $background;
                margin: 8px;
                border-radius: 10px;
                cursor: pointer;

                p.selected {
                    margin-right: 12px;
                }
            }
        }


    }
}