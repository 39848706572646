@import '../../variables';

.sort-filter-mobile {
    @media (min-width: 742px) {
        display: none;
    }

    .circle {
        position: absolute;
        top: 10px;
        /* Adjust this value based on your design */
        right: -10px;
        /* Adjust this value based on your design */
        width: 10px;
        height: 10px;
        background-color: $text-color;
        /* Adjust the background color as needed */
        border-radius: 50%;
    }

    .sort-filter-buttons {
        background-color: $background-light;
        display: flex;
        border-radius: 10px;
        position: relative;
        justify-content: space-around;
        width: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 16px;
            left: 50%;
            width: 1px; // Adjust the line width as needed
            height: 50%;
            background-color: #FFFFFF; // Adjust the line color as needed
            transform: translateX(-50%);
        }

        button {
            position: relative;
            display: flex;
            background: transparent;
            border: none;
            color: $text-color;
            height: 70px;
            align-items: center;
            font-family: 'Overpass Mono';

            img {
                width: 40px;
                height: auto;

                @media (max-width: 400px) {
                    width: 30px;
                }
            }

            p {
                font-size: 16px;
                font-weight: 300;
                margin-left: 6px;

                @media (max-width: 400px) {
                    font-size: 14px;
                }
            }

            &:last-child {
                p {}
            }
        }

    }

    .sort-content,
    .filter-content {
        .list {
            display: flex;
            flex-direction: column;

            button {
                background-color: $background-light;
                border: none;
                color: $text-color;
                font-family: Overpass;
                font-size: 16px;
                padding: 16px;
                text-align: left;
                border-radius: 10px;
                margin-top: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                p.selected {
                    margin-right: 16px;
                    font-size: 28px;
                }
            }
        }

        .sort-list-control,
        .filter-list-control {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin: 12px 0;

            button {
                font-size: 14px;
                background: none;
            }

            .change {
                margin-left: 16px;


                button.reset {
                    color: $cancel-color;
                }

                button.accept {
                    color: $primary-light;
                    margin-left: 12px;
                }


            }

        }

        .filter-inputs {
            .MuiFormControl-root {
                width: 100%;
                margin: 0;
                margin-top: 16px;

                .MuiSelect-select {
                    background-color: $background-light;
                    border-radius: 10px;
                    width: 100%;
                }
            }

            label {
                color: $text-color;
            }

            .MuiSvgIcon-root {
                color: $text-color;
            }

            .MuiChip-root {
                background-color: $background;
                color: $text-color;
            }
        }

    }


}

.MuiPaper-root.MuiMenu-paper {
    background-color: $background-light;
    color: $text-color;
}